import axios from "axios";
import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import { url } from "../apis";
import React from "react";
import { toast } from "react-toastify";
import CustomConsole from "../../../CustomConsole";

//function for getting wfsdetails
export const GetWfsDetailsSku = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      // Remove empty keys from reqObj
      const filteredReqObj = Object.fromEntries(
        Object.entries(reqObj).filter(([key, value]) => value !== "")
      );

      let response = await api.get(
        `OM/wfs/available/details?${new URLSearchParams(filteredReqObj)}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.WFS_DETAILS_SKU,
          payload: response.data,
        });
        return response?.data;
      } else {
        return response?.data;
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error?.response?.data?.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
      return error?.response?.data;
    }
  };
};

//function for getting wfsdetails batch details
export const GetWfsBatchDetailsSku = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      // Remove empty keys from reqObj
      const filteredReqObj = Object.fromEntries(
        Object.entries(reqObj).filter(([key, value]) => value !== "")
      );

      let response = await api.get(
        `OM/wfs/batch/details?${new URLSearchParams(filteredReqObj)}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.WFS_BATCH_DETAILS_SKU,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//function for creating wfsBatch batch details
export const CreateWfsBatchSku = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`OM/wfs/batch/create`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CREATE_WFS_BATCH_SKU,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error?.response?.data?.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//function for Editing wfsBatch batch details
export const EditWfsBatchSku = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`OM/wfs/batch/edit`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.EDIT_WFS_BATCH_SKU,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//function for Cancling wfsBatch batch details
export const CancelWfsBatchSku = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`OM/wfs/batch/cancel`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CANCEL_WFS_BATCH_SKU,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//function for groping wfsBatch batch details
export const GroupProductsWfsSku = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`QSM/products/grouping`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GROUP_PRODUCT_WFS_SKU,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//function for Cancling grouping wfsBatch batch details
export const CancelGroupProductsWfsSku = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`QSM/products/grouping/cancel`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CANCEL_GROUP_PRODUCT_WFS_SKU,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
export const CancelGroupProduct = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`QSM/product/grouping/cancel`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CANCEL_GROUPING_PRODUCT,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//function for linking qr less product to batch batch details
export const LinkQrLessProductToBatch = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`OM/wfs/batch/link/qrless`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.LINK_QRLESS_PRODUCT,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//function for wfs empty can orders
export const WfsEmptyCanOrdersSku = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/operations/wfs/order`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.WFS_EMPTY_CAN_ORDERS,
          payload: response.data,
        });
        toast.success(response.data.msg + " " + response.data.data.order_id, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//function for getting wfsdetails batch details
export const GetWfsEmptyCanDetailsSku = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      // Remove empty keys from reqObj
      const filteredReqObj = Object.fromEntries(
        Object.entries(reqObj).filter(([key, value]) => value !== "")
      );

      let response = await api.get(
        `/OM/operations/wfs/orders?${new URLSearchParams(filteredReqObj)}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.WFS_EMPTYCAN_DETAILS_SKU,
          payload: response.data,
        });
      } else {
        dispatch({
          type: actionType.WFS_EMPTYCAN_DETAILS_SKU,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole(error);
      dispatch({
        type: actionType.WFS_EMPTYCAN_DETAILS_SKU,
        payload: error.response.data,
      });
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//function for getting wfsdetails batch details
export const CompleteEmptyCanOrderOtp = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`OM/operations/wfs/otp/verify`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.WFS_COMPLETE_ORDER_OTP,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//function for getting wfsdetails batch details
export const EditEmptyCanOrdersSku = (orderId, reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(
        `OM/operations/wfs/edit/qty/${orderId}`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.EDIT_WFS_EMPTYCAN_SKU,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//function for wfs empty can Accept  orders
export const WfsEmptyCanAcceptOrdersSku = (params, reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(
        `/OM/operations/wfs/order/accept/${params}`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.WFS_EMPTY_CAN_ACCEPT_ORDER,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//function for wfs empty can Accept  orders
export const WfsEmptyCanRejectOrdersSku = (params, reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(
        `/OM/operations/wfs/order/reject/${params}`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.WFS_EMPTY_CAN_REJECT_ORDER,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//function for wfs empty can Accept  orders
export const AssignDeliverAgentEmptyCan = (params, reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(
        `/OM/operations/wfs/order/assignAgent/${params}`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.ASIGN_EMPTYCAN_AGENT,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//function for edit ware house empty orders
export const EditWareHouseEmptycan = (params, reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(
        `/OM/operations/wfs/edit/warehouse/${params}`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.EDIT_WAREHOUSE_EMPTYCAN,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
