import {
  Add,
  FilterList,
  FirstPage,
  LastPage,
  PlusOne,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetGiftCarData,
  GetListOfGiftCardDetails,
  PostCreateSingleUserGiftCard,
} from "../Redux/Actions/AdminSettingsAction";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import GiftCardFilterDialog from "./GiftCardFilterDialog";
import AddGiftCard from "../UserDetails/AddGiftCard";

function GiftCardsMain() {
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [selectedFilters, setSelectedFilters] = React.useState({});
  const [openGiftCardFilterDialog, setOpenGiftCardFilterDialog] =
    React.useState(false);
  const [isBookwaterConsumer, setIsBookwaterConsumer] = React.useState("");
  const [openCreateGiftCard, setOpenCreateGiftCard] = React.useState(false);
  const dispatch = useDispatch();
  const settingsDetails = useSelector((state) => state.adminSettingsData);

  React.useEffect(() => {
    const reqObj = {
      pageNumber: pageNumber - 1,
      pageSize: pageSize,
      category: selectedFilters?.category || undefined,
      giftCardId: selectedFilters?.giftCardId || undefined,
      status: selectedFilters?.status || undefined,
      phoneNumber_emailId: selectedFilters?.phoneNumber || undefined,
      from: selectedFilters?.createdDate || undefined,
      to: selectedFilters?.expiryDate || undefined,
    };
    dispatch(GetListOfGiftCardDetails(reqObj));
    dispatch(GetGiftCarData());
  }, [pageNumber, pageSize]);

  const handleOpenGiftCardFilter = () => {
    setOpenGiftCardFilterDialog(true);
  };
  const handleFilterGiftCard = (props) => {
    setSelectedFilters(props);
    const reqObj = {
      pageNumber: pageNumber - 1,
      pageSize: pageSize,
      category: props?.category || undefined,
      giftCardId: props?.giftCardId || undefined,
      status: props?.status || undefined,
      phoneNumber_emailId: props?.phoneNumber || undefined,
      from: props?.createdDate || undefined,
      to: props?.expiryDate || undefined,
    };
    dispatch(GetListOfGiftCardDetails(reqObj));

    setOpenGiftCardFilterDialog(false);
  };
  const handleOpenCreateGiftCard = () => {
    setIsBookwaterConsumer("NO");
    setOpenCreateGiftCard(true);
  };
  const handleClodeGiftCard = () => {
    setOpenGiftCardFilterDialog(false);
  };

  const handleSubmitGiftCard = (data) => {
    console.log("data", data);
    const reqObj = {
      amount: parseInt(data.amount),
      isPasswordRequired: data.isPasswordRequired,
      redeemThrough: data.redeemThrough,
      receiver: {
        phone: data.phone,
        email: data.email,
        isLoadGiftMoneyDirectly: data.isLoadWalletDirectly,
      },
      userGreeting: {
        ownWishes: data.ownWishes || undefined,
        selectedCategory: data.selectedCategory || undefined,
        selectedGreetingImage: data.selectedGreetingImage || undefined,
      },
    };
    console.log(reqObj);
    if (
      data.amount !== "" &&
      data.isPasswordRequired !== "" &&
      data.redeemThrough !== "" &&
      data.isLoadWalletDirectly !== "" &&
      data.phone !== "" &&
      data.email !== ""
    ) {
      dispatch(PostCreateSingleUserGiftCard(reqObj)).then((data) => {
        if (data?.error === false) {
          handleFilterGiftCard();
          setOpenCreateGiftCard(false);
        }
      });
    }
  };
  const handleCloseGitCard = () => {
    setOpenCreateGiftCard(false);
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: "10px",
          gap: "10px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={
            settingsDetails?.getGiftCarddata?.gift_card_settings?.isEnable ===
            false
          }
          variant="contained"
          color="success"
          size="small"
          onClick={() => handleOpenCreateGiftCard()}
        >
          <Add />
          Create Gift Card
        </Button>
        <Button variant="contained" onClick={() => handleOpenGiftCardFilter()} size="small">
          <FilterList />
          Filter
        </Button>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "column",
              lg: "column",
            },
            alignItems: "center",
          }}
        >
          <InputLabel sx={{ fontSize: "12px", fontWeight: "bold" }}>
            Page Size:
          </InputLabel>
          <Select
            variant="standard"
            sx={{ fontSize: "12px", fontWeight: "bold", width: "80px" }}
            value={pageSize}
            label="Page Size"
            onChange={(event) => setPageSize(event.target.value)}
          >
            <MenuItem value="10">10</MenuItem>
            <MenuItem value="25">25</MenuItem>
            <MenuItem value="50">50</MenuItem>
            <MenuItem value="100">100</MenuItem>
            <MenuItem value="200">200</MenuItem>
            <MenuItem value="300">500</MenuItem>
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={() => {
              if (pageNumber - 1 > 0) {
                setPageNumber(() => pageNumber - 1);
              }
            }}
          >
            <FirstPage sx={{ color: pageNumber - 1 > 0 ? "blue" : "gray" }} />
          </IconButton>
          <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
            {pageNumber}
          </Typography>
          <IconButton
            onClick={() => {
              if (
                settingsDetails?.getListOfGiftCarddetails?.length ===
                  pageSize &&
                pageNumber + 1 > 0
              ) {
                setPageNumber(() => pageNumber + 1);
              }
            }}
          >
            <LastPage
              sx={{
                color:
                  settingsDetails?.getListOfGiftCarddetails?.length === pageSize
                    ? "blue"
                    : "gray",
              }}
            />
          </IconButton>
        </Box>
        {/* <Box sx={{ mt: "5px" }}>
          <CSVLink {...csvLink}>
            <DownloadForOffline
              onClick={() => toCSV(nextDueDatesList)}
              sx={{ fontSize: 33 }}
            />
          </CSVLink>
        </Box> */}
      </Box>
      <TableContainer sx={{height:"600px"}}>
        <Table stickyHeader>
          <TableHead >
            <TableRow sx={{ bgcolor: "#f4ecf7" }} >
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                S.no
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Gift Card Id
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Paying Amount
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Payment Method
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Redeem Through
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Status
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Password
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Receiver Phone
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Receiver Email
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Is Load Wallet Directly
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Own Wishes
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Selected Greeting
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Creator Name
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Creator Phone
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Created Date
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Created By
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Expiry Date
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {settingsDetails?.getListOfGiftCarddetails !== undefined &&
            settingsDetails?.getListOfGiftCarddetails !== null &&
            settingsDetails?.getListOfGiftCarddetails.length > 0 ? (
              <>
                {settingsDetails?.getListOfGiftCarddetails.map(
                  (data, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {data.details?.giftCardId}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {data.details?.payingAmount}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {data.details?.paymentMethod
                            ? data.details?.paymentMethod
                            : "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {data.details?.redeemThrough}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {moment(data?.details?.expiryDate).isBefore(moment())
                            ? "EXPIRED"
                            : data.details?.status === 100
                            ? "PAYMENT_PENDING"
                            : data.details?.status === 200
                            ? "READY_TO_CLAIM"
                            : data.details?.status === 300
                            ? "ALREADY_CLAIMED"
                            : data.details?.status === 400
                            ? "EXPIRED"
                            : "--"}
                        </TableCell>

                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {data.details?.password}
                        </TableCell>

                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {data.details?.receiver?.phone
                            ? data.details?.receiver?.phone
                            : "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {data.details?.receiver?.email
                            ? data.details?.receiver?.email
                            : "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {data.details?.receiver?.isLoadGiftMoneyDirectly ===
                          true
                            ? "YES"
                            : "NO"}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {data.details?.greeting?.ownWishes
                            ? data.details?.greeting?.ownWishes
                            : "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {data.details?.greeting?.selectedCategory
                            ? data.details?.greeting?.selectedCategory
                            : "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {data.details?.creatorName}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {data.details?.creatorPhone}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {moment(data.details?.createdDate)
                            .tz("Asia/Kolkata")
                            .format("DD.MM.YYYY")}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {data.details?.roleId === ADMIN_ROLES.BW_ADMIN
                            ? "ADMIN"
                            : data.details?.roleId === ADMIN_ROLES.BW_SUPERADMIN
                            ? "SUPER ADMIN"
                            : data.details?.roleId === ADMIN_ROLES.BW_CS
                            ? "CS"
                            : data.details?.roleId === ADMIN_ROLES.BW_CSHEAD
                            ? "CS HEAD"
                            : data.details?.roleId === ADMIN_ROLES.BW_CSLEAD
                            ? "CS LEAD"
                            : data.details?.roleId === ADMIN_ROLES.BW_CONSUMER
                            ? "CONSUMER"
                            : "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {moment(data.details?.expiryDate)
                            .tz("Asia/Kolkata")
                            .format("DD.MM.YYYY")}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={14} align="center">
                  <Typography sx={{ color: "red" }}>No Data Found</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <GiftCardFilterDialog
        openGiftCardFilterDialog={openGiftCardFilterDialog}
        handleClodeGiftCard={handleClodeGiftCard}
        handleFilterGiftCard={handleFilterGiftCard}
      />
      {openCreateGiftCard && (
        <AddGiftCard
          openCreateGiftCard={openCreateGiftCard}
          handleSubmitGiftCard={handleSubmitGiftCard}
          handleCloseGitCard={handleCloseGitCard}
          settingsDetails={settingsDetails}
          isBookwaterConsumer={isBookwaterConsumer}
        />
      )}
    </Box>
  );
}

export default GiftCardsMain;
