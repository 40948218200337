import { Box, Typography } from "@mui/material";
import React from "react";
import PaymentSummaryDashboard from "./PaymentSummaryDashboard";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import SalesSummaryDashboardNew from "./SalesSummaryDashboardNew";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import WareHouseDashBoardMain from "./wareHouseDshBoard";
import IotLiveDataDashBoard from "./IotDashBoard/IotLiveDataGraph";
const useStyles = makeStyles({
  tabUi: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-evenly",
    },
    "& .PrivateTabIndicator-colorSecondary-12": {
      backgroundColor: "#FFF",
    },
    "& .MuiTab-wrapper": {
      fontWeight: "600",
    },
  },
});
function AdmiNewDashboardMain() {
  const theme = useTheme();
  const classes = useStyles();
  const [valueDashboard, setValueDashboard] = React.useState("1");
  const handleChangeIndexDashboard = (index) => {
    setValueDashboard(index);
  };

  const handleChangeDashboard = (event, newValue) => {
    setValueDashboard(newValue);
  };
  return (
    <Box sx={{ mt: "10px" }}>
      <Box sx={{ bgcolor: "background.paper" }}>
        <TabContext value={valueDashboard}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChangeDashboard}
              sx={{ background: "#B6D0E2" }}
              className={classes.tabUi}
            >
              <Tab label="Sales Details" value="1" />
              <Tab label="Warehouse Details" value="2" />
              <Tab label="Iot Data" value="3" />

            </TabList>
          </Box>
          <TabPanel value="1">
            <SalesSummaryDashboardNew />
          </TabPanel>
          <TabPanel value="2">
            <WareHouseDashBoardMain />
          </TabPanel>
          <TabPanel value="3">
            <IotLiveDataDashBoard />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}

export default AdmiNewDashboardMain;
