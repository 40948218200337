import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import appStyle from "../../Components/AppMeta/appStyles";
import { Box } from "@mui/material";
import BasicSelectField from "../Custom/selectField";

function CustomFilter({
  name,
  startIcon,
  selectOption = [],
  handleFilterClose,
  type,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectValue, setSelectValue] = React.useState(selectOption[0]?.value);
  const [filterSearchValue, setFilterSearchValue] = React.useState("");
  const [sortOrder, setSortOrder] = React.useState("ASC");
  const [selectedFilterOption, setSelectedFilterOption] = React.useState({});
  const [isActive, setIsActive] = React.useState(true);
  const open = Boolean(anchorEl);
  // console.log("selectOption", selectOption);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    let filterData = {};

    filterData = {
      value: selectValue,
      searchText: type === "filter" ? filterSearchValue : sortOrder,
    };

    handleFilterClose !== undefined && handleFilterClose(filterData);
    setAnchorEl(null);
  };
  const handleSelectChange = (event) => {
    // console.log(event.target.value);
    setSelectValue(event.target.value);
    setFilterSearchValue("");
  };

  // const handleFilterSearchChange = (event) => {
  //   setFilterSearchValue(event.target.value);
  // };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
    setFilterSearchValue("");
  };

  React.useEffect(() => {
    console.log("selectOption", selectOption);
    console.log("selectValue", selectValue);
    if (selectOption) {
      const selectedFilteredNames = selectOption?.find(
        (val) => val.value === selectValue
      );
      setSelectedFilterOption(selectedFilteredNames);
      // console.log("selectedFilteredNames", selectedFilteredNames.value);
    }
  }, [selectOption, selectValue]);

  React.useEffect(() => {
    console.log("----Select value---");
    console.log(filterSearchValue);
  }, [filterSearchValue]);

  return (
    <>
      <Button
        id="basic-button"
        onClick={handleClick}
        variant="contained"
        startIcon={startIcon}
        sx={appStyle.general.button.blueButton}
      >
        {name}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        onKeyDown={(e) => {
          // Prevent the default behavior of focusing next/prev menu items on specific keys
          if (
            e.key === "s" ||
            e.key === "S" ||
            e.key === "F" ||
            e.key === "f"
          ) {
            setFilterSearchValue((prev) => prev + e.key);
            e.stopPropagation(); // Prevents focus from shifting
          }
        }}
      >
        <MenuItem>
          <FormControl sx={{ m: 1, width: "200px" }} variant="standard">
            <InputLabel id="demo-customized-select-label">
              Select Option
            </InputLabel>
            <Select value={selectValue} onChange={handleSelectChange}>
              {selectOption.map((e, i) => {
                return (
                  <MenuItem value={e.value} key={i}>
                    {e.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </MenuItem>

        {/* Code for filter */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {type === "filter" &&
            (selectValue === "is_active" ? (
              <BasicSelectField
                name="Active status"
                input={[
                  { value: true, menuItem: "Active" },
                  { value: false, menuItem: "In active" },
                ]}
                defaultValue={isActive}
                handleChange={(e) => {
                  setIsActive(e.target.value);
                  setFilterSearchValue(e.target.value);
                }}
                sx={{ width: "200px" }}
              />
            ) : (
              <TextField
                id="filterSearch"
                label={"Search" + " " + selectedFilterOption?.value}
                variant="standard"
                value={filterSearchValue}
                onChange={(e) => {
                  setFilterSearchValue(e.target.value);
                }}
                sx={{ width: "200px" }}
              />
            ))}
        </Box>

        {/* code for sort */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {type === "sort" && (
            <ToggleButtonGroup
              value={sortOrder}
              exclusive
              onChange={handleSortChange}
              aria-label="text alignment"
            >
              <ToggleButton value="ASC" aria-label="left aligned">
                ASC
              </ToggleButton>
              <ToggleButton value="DESC" aria-label="left aligned">
                DESC
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        </Box>
        <MenuItem>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={appStyle.general.button.blueButton}
          >
            {type === "filter" ? "Filter" : type === "sort" ? "sort" : "Filter"}
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
}

export default CustomFilter;
