export const tabelConfig = {
  rawMaterialTabel: {
    //sample data
    headerData: [
      "S No",
      "Name",
      "Material",
      "Units",
      "Created At",
      "Sgst",
      "Cgst",
      "HSN",
      "Status",
      "Action",
    ],
    tabelDataKey: [
      "name",
      "material",
      "units",
      "created_at",
      "other_details.empty_cgst_perc",
      "other_details.empty_sgst_perc",
      "other_details.empty_hsn_code",
    ],
  },
  PhysicalGiftCardTable: {
    headerData: [
      "S No",
      "Batch Name",
      "Amount Per Card",
      "Total Cards",
      "Created by",
      "Created At",
      "Status",
      "Action",
    ],
    tabelDataKey: [
      "details.batchName",
      "details.amount_perGiftCard",
      "details.numberOfGiftCards",
      "details.currentAssignee.fullname",
      "details.creatorName",
      "details.createdDate",
    ],
  },
  PhysicalGiftCardBatchAssigneedTable: {
    headerData: [
      "S No",
      "Name",
      "Phone",
      "Assigned Date",
      "Total Given",
      "Total Sold",
    ],
    tabelDataKey: [
      "name",
      "phone",
      "Assigned_date",
      "numberOfGiftCards_Given",
      "numberOfGiftCards_sold",
    ],
  },
  PhysicalGiftCardBatchTransactionsTable: {
    headerData: [
      "S No",
      "Name",
      "Phone",
      "Submitted Date",
      "Payment Received ₹",
      "Total Sold Qty",
    ],
    tabelDataKey: [
      "assigneeName",
      "assigneePhone",
      "updatedOn",
      "paymentReceived",
      "numberOfGiftCards_sold",
    ],
  },
  manufactureData: {
    headerData: [
      "S no",
      "Manufacturer",
      "Owner Name",
      "Owner Phone",
      "Owner Email",
      "Gst In",
      "Address 1",
      "Address 2",
      "status",
      "Action",
    ],
    tabelDataKey: [
      "manufacturer_name",
      "branch",
      "company_profile.owner_name",
      "company_profile.owner_phone",
      "company_profile.owner_email",
      "tax_details.gstin",
      "company_profile.address.address_line1",
      "company_profile.address.address_line2",
    ],
  },
  manufactureOrderData: {
    headerData: [
      "S no",
      "Order Id",
      "Manufacture",
      "CGST(₹)",
      "SGST(₹)",
      "Total(₹)",
      "Date",
      "Order Status",
      "Action",
    ],
    tabelDataKey: [
      "order_id",
      "mft_details.manufacturer_name",
      "order_summary.cgst",
      "order_summary.sgst",
      "order_summary.order_total",
      "created_at",
    ],
  },
};
