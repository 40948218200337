/**
 * @author vinay kumar
 * @description Return Can Filter
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.52
 * @Date 27-10-2023
 */
// Importing the Libraries and Other Files

import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { returnCanOrderStatus } from "../AppMeta/appMetaConfig";
import {
  Box,
  Dialog,
  DialogContent,
  TextField,
  Typography,
  Button
} from "@mui/material";


function ReturnCanFilter(props) {
  const [orderId, setOrderId] = useState(props.orderId);

  // const [orderStatus, setOrderStatus] = React.useState(returnCanOrderStatus.CONSUMER_ACCEPTED);

  const handleClose = () => {
    props.handleClose();
  };
  const handleFilter = () => {
    props.setOrderId(orderId);
    setTimeout(() => {
      props.handleFilter();
    }, 300);
  };

  //function to clear filter
  const handelClearFilter = () => {
    props.handelClearFilter();
  };

  return (
    <Box>
      <Dialog open={props.openFilterDialog}>
        <DialogContent>
          <Typography sx={{ marginBottom: "20px" }}>Filter Orders</Typography>
          <Box sx={{ minWidth: 220, marginBottom: "20px" }}>
            <FormControl fullWidth>
              <InputLabel>Order Status</InputLabel>
              <Select
                value={props.orderStatus}
                label="Order Status"
                onChange={(event) => props.setOrderStatus(event.target.value)}
              >
                <MenuItem value={returnCanOrderStatus.DELIVERED}>
                  DELIVERED
                </MenuItem>
                <MenuItem value={returnCanOrderStatus.APPROVED}>
                  ACCEPTED
                </MenuItem>
                <MenuItem value={returnCanOrderStatus.CONSUMER_ACCEPTED}>
                  CONSUMER ACCEPTED
                </MenuItem>
                {/* <MenuItem value={returnCanOrderStatus.REFUND}>REFUND</MenuItem> */}
                {/* <MenuItem value={returnCanOrderStatus.CONSUMER_REJECTED}>
                  CONSUMER REJECTED
                </MenuItem> */}
                {/* <MenuItem value={returnCanOrderStatus.DELIVERED}>
                  COMPLETED
                </MenuItem> */}
                <MenuItem value={returnCanOrderStatus.REJECTED}>
                  CANCELLED
                </MenuItem>
                <MenuItem value={returnCanOrderStatus.PENDING}>
                  PENDING
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 220 }}>
            <FormControl fullWidth>
              <TextField
                defaultValue={"WTHDRWL"}
                placeholder="WTHDRWL"
                value={orderId}
                onChange={(event) =>
                  setOrderId(event.target.value ? event.target.value : "")
                }
                label="Order Id"
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px",
            }}
          >
            <Button
              variant="contained"
              onClick={handleClose}
              color="error"
              size="small"
            >
              Close
            </Button>
            <Button
              variant="contained"
              onClick={handelClearFilter}
              color="warning"
              size="small"
            >
              Clear
            </Button>
            <Button variant="contained" onClick={handleFilter} size="small">
              Filter
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ReturnCanFilter;
