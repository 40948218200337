/**
 * @Copyright 2024 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description
 * @date
 * @version
 */
import { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch } from "react-redux";
import CustomConsole from "../../CustomConsole";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import TextFieldReusable from "../TextFiled/textField";
import CoustomTextField from "../TextFiled/coustomTextField";
function CoustomAddItemsDialog({
  openDialog,
  handelClose,
  dialogTittle,
  fieldsConfig,
  textFieldType,
  submitButton,
  formValues,
  setFormValues,
  fieldValidity,
  setFieldValidity,
}) {
  // Handle field change dynamically
  const handleChange = (fieldName, value, isValid) => {
    console.log(value);
    setFormValues((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
    setFieldValidity((prev) => ({
      ...prev,
      [fieldName]: isValid,
    }));
  };
  //handel valid data
  const handleValidation = (fieldName, isValid) => {
    setFieldValidity((prev) => ({
      ...prev,
      [fieldName]: isValid,
    }));
  };
  //handel closeDialog
  const handelCloseDialog = () => {
    setFormValues({});
    handelClose();
  };
  return (
    <Box>
      <Dialog open={openDialog}>
        <DialogTitle>
          <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
            {dialogTittle}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {fieldsConfig?.map((field, index) => {
              switch (field.textFieldType) {
                case "text":
                  return (
                    <CoustomTextField
                      {...field}
                      label={field.label}
                      name={field.name}
                      type={field.type}
                      value={formValues[field.name]}
                      validationRegex={field.regex} // At least 3 characters
                      isRequired={field.isRequired}
                      handleChange={handleChange}
                      setIsValid={handleValidation}
                      isHintRequired={field.isHintRequired}
                    />
                  );
                case "select":
                  return (
                    <FormControl
                      key={index}
                      variant="outlined"
                      size="small"
                      sx={{ minWidth: 210 }}
                    >
                      <InputLabel>{field.label}</InputLabel>
                      <Select
                        value={formValues[field.name] || ""}
                        onChange={(e) =>
                          handleChange(field.name, e.target.value)
                        }
                      >
                        {field.options.map((option, i) => (
                          <MenuItem value={option.value} key={i}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                case "radio":
                  return (
                    <FormControl key={index} component="fieldset">
                      <Typography>{field.label}</Typography>
                      <RadioGroup
                        value={formValues[field.name] || ""}
                        onChange={(e) =>
                          handleChange(field.name, e.target.value)
                        }
                      >
                        {field.options.map((option, i) => (
                          <FormControlLabel
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                            key={i}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  );
                default:
                  return null;
              }
            })}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", gap: 2 }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={handelCloseDialog}
          >
            Close
          </Button>
          {submitButton(formValues)}
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default CoustomAddItemsDialog;
