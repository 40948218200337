import { Close, Phone } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import bgImage from "../../../Components/Assests/Images/bw_bg.svg";
import moment from "moment";
import CustomTable from "../../../Components/Tables/CoustomTable";
import { tabelConfig } from "../../../Components/AppMeta/appTabelConfig";
import CoustomTextField from "../../../Components/TextFiled/coustomTextField";
import {
  appRegex,
  GIFTCARD_STATUS,
} from "../../../Components/AppMeta/appMetaConfig";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import CoustomAddItemsDialog from "../../../Components/Custom/addItemsDialog";
import { textFieldConfig } from "../../../Components/AppMeta/appTextFieldConfig";
import PrimaryButton from "../../../Components/Buttons/primaryButton";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { appColors } from "../../../Components/Assests/AppTheme/appThemeConst";
import { useDispatch } from "react-redux";
import {
  GetPhysicalGiftCards,
  PhysicalGiftCardChangeStatus,
  SettlePhysicalGiftCard,
} from "../../Redux/Actions/AdminSettingsAction";
import RenderGiftCards from "./RenderGiftCard";
import useProductImages from "../../../Components/Custom/useProductImages";

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
//   });
export default function GiftCardBatchDetails({
  open,
  handleClose,
  giftCardSetting,
  setSelectedGiftCard,
  giftCardId
}) {
  const { images, isLoading, error } = useProductImages(
    giftCardSetting?.physical_giftcard_template,
    "GiftCardImages"
  );
  const [details, setBatchDetails] = useState();
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [updateAssignee, setUpdateAssignee] = useState(false);
  const [editDetails, setEditDetails] = useState({});
  const [isValid, setIsValid] = useState();
  const dispatch = useDispatch();
  const [toggleValue, setToggleValue] = useState("Transactions");
  const [openSettlementDialog, setOpenSettlementDialog] = useState(false);
  const [newSettlementValues, setNewSettlementValues] = useState({});
  const [fieldValidity, setFieldValidity] = useState({});
  const [updateBatchStatus, setUpdateBatchStatus] = useState();
  const [renderData, setRenderData] = React.useState([]);

  const handelOpenData = () => {
    console.log(giftCardSetting);
    let data = details?.details?.linkedGiftCards?.map((card) => ({
      amount: details?.details.amount_perGiftCard,
      id: card.giftCardId,
      branch: details?.details.batchName,
      password: card.password,
    }));
    setRenderData(data);
    setOpenDownloadModal(true);
  };
  const onSubmitSettlement = () => {
    const payload = {
      ...newSettlementValues,
      id: details.id,
      batchName: details?.details?.batchName,
    };
    dispatch(SettlePhysicalGiftCard(payload)).then((data) => {
      if (!data?.error) {
        setOpenSettlementDialog(false);
      }
      getGiftcards();
    });
  };
  const getGiftcards = () => {
    let payload = {
      id: giftCardId.id,
      batchName: giftCardId?.details?.batchName,
    };
    dispatch(GetPhysicalGiftCards(payload)).then((data) => {
      if (!data.error) {
        setBatchDetails(data?.data?.[0]);
      }
      // setGiftCards(data?.data);
    });
  };
  useEffect(() => {
    if (giftCardId?.id) {
      getGiftcards();
    }
  }, [giftCardId]);

  const onUpdateBatchStatus = (status) => {
    const payload = {
      id: details?.id,
      batchName: details?.details?.batchName,
      changeTo: status,
    };
    dispatch(PhysicalGiftCardChangeStatus(payload)).then((data) => {
      if (!data.error) {
        getGiftcards();
        setUpdateBatchStatus();
      }
    });
  };

  const GiftCardSubmitBtn = (props) => {
    return (
      <Box>
        <PrimaryButton
          btnName="Submit"
          handleButtonClick={() => {
            // submitCreateGc(props);
            onSubmitSettlement();
            // handelSubmitManufacture(props)
          }}
          color={appColors.commonBtnColor}
        />
      </Box>
    );
  };

  //function to change status
  const handelChangeStatus = (event) => {
    console.log(details?.details?.status);
    if (details?.details?.status === GIFTCARD_STATUS.DEACTIVE) {
      onUpdateBatchStatus(GIFTCARD_STATUS.ACTIVE);
      setUpdateBatchStatus(GIFTCARD_STATUS.ACTIVE);
    } else {
      onUpdateBatchStatus(GIFTCARD_STATUS.DEACTIVE);
      setUpdateBatchStatus(GIFTCARD_STATUS.DEACTIVE);
    }
  };

  console.log(details)
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      // TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", backgroundColor: "#1b185c" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            GiftCard Batch Details
          </Typography>
          <Button
            variant="contained"
            autoFocus
            color="inherit"
            sx={{ color: "red" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContentText
        sx={{
          // backgroundImage: `url(${bgImage})`,
          height: "100vH",
          width: "100vW",
          alignItems: "center",
          padding: "15px",
          gap: "20px",
        }}
      >
        <Box sx={{ color: "#808080" }}>
          <Box sx={{ display: "flex", gap: 5, alignItems: "center" }}>
            <Box>Status : </Box>
            <div>
              {details?.details?.status == GIFTCARD_STATUS.DEACTIVE ||
              details?.details?.status == GIFTCARD_STATUS.PRINTING
                ? false
                : true}
              {details?.details?.status}
              <Switch
                checked={
                  details?.details?.status == GIFTCARD_STATUS.DEACTIVE ||
                  details?.details?.status == GIFTCARD_STATUS.PRINTING
                    ? false
                    : true
                }
                onChange={handelChangeStatus}
                icon={<Brightness4Icon />}
                checkedIcon={<Brightness7Icon />}
                size="medium"
                aria-label="choose mode"
              />
                {details?.details?.status == GIFTCARD_STATUS.DEACTIVE ||
              details?.details?.status == GIFTCARD_STATUS.PRINTING
                ? "Deactive"
                : "Active"}
              
            </div>
            <div style={{dispatch:"flex",justifyContent:"center",alignContent:"center"}}>
              <CloudDownloadIcon
                onClick={() => {
                  handelOpenData();
                }}
              /> DownLoad PDF
            </div>
          </Box>
          <Box sx={{ display: "flex", gap: 5 }}>
            <Box sx={{ gap: 5 }}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ fontWeight: "bold" }}>Batch Name :</Box>
                <Box>
                  <Box>{details?.details?.batchName}</Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ fontWeight: "bold" }}>Price Per Card :</Box>
                <Box>
                  <Box>₹ {details?.details?.amount_perGiftCard}</Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ fontWeight: "bold" }}>Total Cards :</Box>
                <Box>
                  <Box> {details?.details?.numberOfGiftCards}</Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ fontWeight: "bold" }}>Currently Assigned To :</Box>
                <Box>
                  <Box>
                    {details?.details?.currentAssignee?.name}{" "}
                    {!updateAssignee ? (
                      <Button
                        onClick={() => setUpdateAssignee(true)}
                        sx={{ ml: 2 }}
                        size="small"
                        variant="contained"
                      >
                        Update
                      </Button>
                    ) : (
                      <Box sx={{ display: "flex" }}>
                        <Box
                          backgroundColor={"white"}
                          sx={{ display: "flex" }}
                          padding={2}
                        >
                          <CoustomTextField
                            label={"Assigned To Phone"}
                            name={"Phone"}
                            type={"text"}
                            value={editDetails?.phone}
                            validationRegex={appRegex.phoneRegEx} // At least 3 characters
                            isRequired={true}
                            handleChange={(fieldName, value, isValid) => {
                              setEditDetails((prev) => ({
                                ...prev,
                                phone: value,
                              }));
                              setIsValid(isValid);
                            }}
                            setIsValid={setIsValid}
                            isHintRequired={true}
                          />
                          {isValid ? <Button>Save</Button> : <></>}
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Phone /> {details?.details?.currentAssignee?.phone}
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ fontWeight: "bold" }}>Assigned On :</Box>
                <Box>
                  <Box>
                    {moment(
                      details?.details?.currentAssignee?.Assigned_date
                    ).format("YYYY-MMM-DD : hh:mm a")}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ gap: 5 }}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ fontWeight: "bold" }}>Password Required :</Box>
                <Box>
                  <Box>
                    {details?.details?.isPasswordRequired ? "Yes" : "No"}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ fontWeight: "bold" }}>Total Sold :</Box>
                <Box>
                  <Box>{details?.details?.sold || 0}</Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ fontWeight: "bold" }}>Total Sold :</Box>
                <Box>
                  <Box>{details?.details?.remaining || 0}</Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ fontWeight: "bold" }}>Amount Collected :</Box>
                <Box>
                  <Box>₹ {details?.details?.collectedAmount || 0}</Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            alignItems: "center",
            width: "100%",
            display: "flex",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ToggleButtonGroup
              color="primary"
              value={toggleValue}
              onChange={(event, newValue) => setToggleValue(newValue)}
              aria-label="Type"
              exclusive
              sx={{ backgroundColor: "wheat" }}
              backgroundColor="white"
              size="small"
            >
              <ToggleButton value="Transactions">Transactions</ToggleButton>
              <ToggleButton value="Assignees">Assignees</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {toggleValue == "Transactions" ? (
            <Button
              onClick={() => setOpenSettlementDialog(true)}
              sx={{ float: "left" }}
              variant="contained"
            >
              Settle
            </Button>
          ) : (
            <></>
          )}
        </Box>
        {toggleValue == "Transactions" ? (
          <CustomTable
            pagination={false}
            tableHead={
              tabelConfig.PhysicalGiftCardBatchTransactionsTable.headerData
            }
            tableData={details?.details?.paymentTransaction}
            tableDataKey={[
              ...tabelConfig.PhysicalGiftCardBatchTransactionsTable
                .tabelDataKey,
            ]}
            pageNumber={0}
            pageSize={0}
            tableRowClickFunction={() => {}}
          />
        ) : (
          <CustomTable
            pagination={false}
            tableHead={
              tabelConfig.PhysicalGiftCardBatchAssigneedTable.headerData
            }
            tableData={details?.details?.assignedTo}
            tableDataKey={[
              ...tabelConfig.PhysicalGiftCardBatchAssigneedTable.tabelDataKey,
            ]}
            pageNumber={0}
            pageSize={0}
            tableRowClickFunction={() => {}}
          />
        )}
      </DialogContentText>
      <CoustomAddItemsDialog
        openDialog={openSettlementDialog}
        handelClose={() => setOpenSettlementDialog(false)}
        dialogTittle="Create Settlement"
        fieldsConfig={textFieldConfig.createGiftCardSettlement}
        submitButton={GiftCardSubmitBtn}
        formValues={newSettlementValues}
        setFormValues={setNewSettlementValues}
        fieldValidity={fieldValidity}
        setFieldValidity={setFieldValidity}
      />
      <DownloadGiftCardsModal
        handleClose={() => setOpenDownloadModal(false)}
        open={openDownloadModal}
        giftCardSetting={giftCardSetting}
        renderData={renderData}
      />
    </Dialog>
  );
}

function DownloadGiftCardsModal({
  open,
  handleClose,
  giftCardSetting,
  renderData,
}) {
  return (
    <Dialog
      fullWidth
      fullScreen
      open={open}
      onClose={handleClose}
      // TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", backgroundColor: "#1b185c" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Download Giftcard
          </Typography>
          <Button
            variant="contained"
            autoFocus
            color="inherit"
            sx={{ color: "red" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </Toolbar>
      </AppBar>
      {open ? (
        <Grid container sx={{ width: "100%", m: 2 }}>
          {giftCardSetting?.physical_giftcard_template?.map((template) => (
            <Grid xs={12} sm={6} md={4} item>
              <Box>
                <RenderGiftCards
                  templateFileName={template}
                  renderData={renderData}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <></>
      )}
    </Dialog>
  );
}
