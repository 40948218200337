import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import CustomConsole from "../../../CustomConsole";
import { toast } from "react-toastify";

export const AppSettings = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/SCM/setupconfig/app_settings`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_APP_SETTINGS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const UpdateAppSettings = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/SCM/setupconfig/app_settings`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_APP_SETTINGS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const AddDistributor = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/TM/tankers/admin`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.ADD_DISTRIBUTOR,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        CustomConsole(response.data.msg);
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
export const GetDistributorDetails = (phone) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      if (phone && phone.length) {
        let response = await api.get(`/TM/pwd_suppliers/${phone}`);
        if (response.status === httpStatus.SUCCESS) {
          dispatch({
            type: actionType.GET_DISTRIBUTOR_DETAILS,
            payload: response.data,
          });
        } else {
          CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        }
      }
    } catch (error) {
      CustomConsole(error);
      dispatch({
        type: actionType.GET_DISTRIBUTOR_DETAILS,
        payload: error.response.data,
      });
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

export const AddDeliveryArea = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      CustomConsole(reqObj);
      let response = await api.post(
        `/TM/pwd_suppliers/pincodes/area_names`,
        reqObj
      );
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.ASSIGNING_DELIVERING_AREA,
          payload: response.data,
        });
      } else {
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          type: "error",
        });
      }
      // toast.success(response.data.msg, {
      //   position: "bottom-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      dispatch({
        type: actionType.ASSIGNING_DELIVERING_AREA,
        payload: error.response.data,
      });
    }
  };
};

export const ServiceAreaAndRate = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SCM/setupconfig/package_drinking_water_settings/${reqObj.pincode}/${reqObj.area}`
      );
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GETTING_PDW_DETAILS,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const UpdatePackageDrinkingWater = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(
        `/SCM/setupconfig/package_drinking_water_settings`,
        reqObj
      );
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATING_PDW_RATE,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const UpdateTransportCharge = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/TM/update/pwd_suppliers`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATING_TRANS_RATE,
          payload: response.data,
        });
        return response?.data
      } else {
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          type: "error",
        });
        return response?.data

      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
      return error?.response?.data
    }
  };
};

export const RemoveTransportCharge = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`/TM/delete/pwd_suppliers`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.REMOVING_TRANS_RATE,
          payload: response.data,
        });
        return response?.data
      } else {
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          type: "error",
        });
        return response?.data
      }
    } catch (error) {
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
      return error?.response?.data

    }
  };
};

export const GetBlockedPayments = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SCM/admin_settings/blocked/payment_methods`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_BLOCKED_PAYMENT_METHODS,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };
};

export const BlockPayments = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`/PM/admin_block/payment/method`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch(GetBlockedPayments());
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };
};

export const GetBlockedBookings = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/SCM/admin_settings/blocked/bookings`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_BLOCKED_BOOKINGS,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };
};

export const BlockBooking = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`/SCM/admin/booking/block`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch(GetBlockedBookings());
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };
};


//function to get admin force app update details
export const GetAdminAppUpdateDetails = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/SSM/admin/app_updates`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.ADMIN_APPUPDATE_STATUS,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};