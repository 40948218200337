/**
 * @Copyright 2024 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Custom Text Field (Inputs)
 * @date 26-11-2024
 * @version
 */

import React, { useState } from "react";
import { TextField, Autocomplete } from "@mui/material";

function CustomSelectField({
  details,
  label = "Select",
  keyName,
  value,
  onChange,
  isRequired = false,
  renderAdditionalDetails
}) {
  // Handle change when an option is selected
  const handleSelectChange = (e, newValue) => {
    if (newValue) {
      onChange(newValue); // Pass selected value
    } else {
      onChange(""); // Handle case where no value is selected
    }
  };
  console.log("details");

  console.log(details);
  console.log(value);

  return (
    <Autocomplete
      options={details}
      getOptionLabel={(option) => option[keyName] || ""}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder="Select"
          size="small"
          error={isRequired && !value}
          helperText={isRequired && !value ? "This field is required" : ""}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option[keyName]}>
          <div>
            <strong>{option[keyName]}</strong>
            {renderAdditionalDetails && (
              <div style={{ fontSize: "0.9rem", color: "#666" }}>
                {renderAdditionalDetails(option)}
              </div>
            )}
          </div>
        </li>
      )}
      onChange={handleSelectChange}
      value={details.find((item) => item[keyName] === value) || null}
      isOptionEqualToValue={(option, value) =>
        option[keyName] === value[keyName]
      }
      sx={{ width: "14rem" }}
    />
  );
}

export default CustomSelectField;
