//import { ActionTypes } from "@mui/base";
import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";

import { toast } from "react-toastify";
import CustomConsole from "../../../CustomConsole";
import { Login } from "@mui/icons-material";

// export const GetWithdrawallist = (page_number) => {
//   const httpStatus = {
//     SUCCESS: 200,
//     BAD_REQUEST: 400,
//   };

//   let api = useAxios();

//   return async (dispatch) => {
//     try {
//       let response = await api.get(
//         `/OM/withdrawalOrderList?page_number=${page_number}`
//       );
//       CustomConsole("---response----");
//       CustomConsole(response);
//       if (response.status === httpStatus.SUCCESS) {
//         dispatch({
//           type: actionType.GET_WITHDRAWAL_LIST,
//           payload: response.data,
//         });
//       }
//     } catch (error) {
//       CustomConsole(error);
//     }
//   };
// };
export const GetWithdrawallist = (props) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/withdrawalOrderList`, {
        params: props,
      });
      CustomConsole("---response----");
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        
     
        dispatch({
          type: actionType.GET_WITHDRAWAL_LIST,
          payload: response.data||[],
        });
      } else {
        dispatch({
          type: actionType.GET_WITHDRAWAL_LIST,
          payload: response.data||[],
        });
      }
    } catch (error) {
      dispatch({
        type: actionType.GET_WITHDRAWAL_LIST,
        payload: error.response.data||[],
      });
    }
  };
};

export const GetWithdrawallistOrderid = (orderId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/admin/withdrawalrequest/${orderId}`, {
        page_number: 0,
      });
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response);
        dispatch({
          type: actionType.GET_WITHDRAWAL_ORDERID,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const PostWithdrawalDetailsrefund = ({ orderId, refund_amount }) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/refundAmountToWallet`, {
        order_id: orderId,
        refund_amount,
      });
      CustomConsole("..RESPONSE..");
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response);
        dispatch({
          type: actionType.POST_WITHDRAWALDETAILS_REFUND,
          payload: response.data,
        });
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 5000,
          type: "success",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 5000,
        type: "error",
      });
    }
  };
};

export const PutEditReturnCanOrder = (orderId, reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(
        `/OM/edit/withdrawalCanOrder/${orderId}`,
        reqObj
      );
      CustomConsole("..RESPONSE..");
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response);
        dispatch({
          type: actionType.PUT_EDIT_RETURN_CAN_ORDERS,
          payload: response.data,
        });
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 5000,
          type: "success",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 5000,
        type: "error",
      });
    }
  };
};
export const PutEditReturnCanOrderDistriToWfs = (orderId, reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(
        `/OM/edit/withdrawalCanOrder_wfs/${orderId}`,
        reqObj
      );
      CustomConsole("..RESPONSE..");
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response);
        dispatch({
          type: actionType.PUT_EDIT_RETURN_CAN_ORDERS_DISTRI_TO_WFS,
          payload: response.data,
        });
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 5000,
          type: "success",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 5000,
        type: "error",
      });
    }
  };
};

export const PutWithdrawalDetailsReject = (orderId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/OM/rejectWithdrawalOrder`, {
        order_id: orderId,
      });
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response);
        toast.dark("Order rejected successfully", {
          position: "bottom-right",
          autoClose: 5000,
          type: "success",
        });
        dispatch({
          type: actionType.PUT_WITHRAWALDETAILS_REJECTED,
          payload: response.data,
        });
      } else {
        toast.dark("Error in rejecting order, contact bookwater support", {
          position: "bottom-right",
          autoClose: 5000,
          type: "error",
        });
        CustomConsole("Reject getting here2");
        CustomConsole("Reject getting here2");
        CustomConsole("Reject getting here2");
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark("Error in rejecting order, contact bookwater support", {
        position: "bottom-right",
        autoClose: 5000,
        type: "error",
      });
      CustomConsole("Reject getting here2");
      CustomConsole("Reject getting here2");
      CustomConsole("Reject getting here2");
      CustomConsole("Reject getting here2");
    }
  };
};

export const GetWithrawalDetailsAccept = (orderId, pageNumber) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  CustomConsole(orderId);
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/OM/getSupplierList/${orderId}?page_number=${pageNumber}`
      );
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        if (!response.data.error) {
          dispatch({
            type: actionType.GET_WITHDRAWALDETAILS_FIRSTACCEPT,
            payload: response.data,
          });
        } else {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 5000,
            type: "error",
          });
        }
      } else {
        toast.dark("Internet not Available or please try after sometime!!!", {
          position: "bottom-right",
          autoClose: 5000,
          type: "info",
        });
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const GetWithdrawalDistributorDetails = (orderId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/OM/getDepositWithdrawalList?order_id=${orderId}`
      );
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_WITHDRAWALDETAILS_SECONDACCEPT,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const AssignDialogAdmin = (supplierid, orderId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/assignWithdrawalSupplier/${orderId}`, {
        supplier_id: supplierid,
      });
      if (response.status === httpStatus.SUCCESS) {
        toast.dark("Admin accepted", {
          position: "bottom-right",
          autoClose: 5000,
          type: "success",
        });
        dispatch({
          type: actionType.POST_WITHDRAWALDETAILS_DIALOG_ASSIGN,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const DamageCansData = (id) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/consumer/getDamagedCans?user_id=${id}`);

      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.DAMAGE_CANS_DATA,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

export const DamageCansAccept = (returnOrderId, reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(
        `/OM/canReturn/consumer/accept/${returnOrderId}`,
        reqObj
      );

      if (response.status === httpStatus.SUCCESS) {
        // CustomConsole("REsponse: " + JSON.stringify(response.data));
        CustomConsole("Response for return cans accept");
        CustomConsole(response);
        dispatch({
          type: actionType.DAMAGE_CAN_ACCEPT,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

export const DamageCansReject = (returnOrderId, reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(
        `/OM/canReturn/consumer/reject/${returnOrderId}`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        // CustomConsole("REsponse: " + JSON.stringify(response.data));
        CustomConsole("Response for return cans reject");
        CustomConsole(response);
        dispatch({
          type: actionType.DAMAGE_CANS_REJECT,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
