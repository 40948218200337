/**
 * @Copyright 2024 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description
 * @date
 * @version
 */
import actionType from "./actionType";
import useAxios from "../../Axios/useAxios";
import { toast } from "react-toastify";
import CustomConsole from "../../../CustomConsole";

export const GetAvailableProducts = (params) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    const filteredReqObj = Object.fromEntries(
      Object.entries(params || {}).filter(([key, value]) => value !== "")
    );
    try {
      let response = await api.get(
        `/SSM/products/available?${new URLSearchParams(filteredReqObj)}`
      );
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_AVAILABLE_PRODUCTS,
          payload: response.data,
        });
        return response?.data;
      } else {
        return response?.data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  };
};
export const GetAvailableProductsByName = async (params = {}) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  // params.ignore_app_loading_spinner = true;
  // Remove empty keys from params
  const filteredReqObj = Object.fromEntries(
    Object.entries(params || {}).filter(([key, value]) => value !== "")
  );

  try {
    let response = await api.get(
      `/SSM/products/available?${new URLSearchParams(filteredReqObj)}`
    );
    return response.data;
  } catch (error) {
    // toast.dark(error.response.data.msg, {
    //   position: "bottom-right",
    //   autoClose: 5000,
    //   type: "info",
    // });
  }
  return [];
};

//function for adding new sku product
export const PostAddNewProduct = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/products/add`, reqObj);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.POST_ADD_NEW_PRODUCT,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.response.data.message || error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      CustomConsole(error);
    }
  };
};

//function for adding new sku crate details
export const AddNewCrateSku = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/products/add/crate`, reqObj);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.POST_ADD_NEW_CRATE,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      CustomConsole(error);
    }
  };
};

//function for adding new sku crate details
export const UpdateSkuProductDetails = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/SSM/products/update`, reqObj);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.POST_ADD_NEW_PRODUCT,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.response.data.message || error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      CustomConsole(error);
    }
  };
};

//function for getting list of crates
export const GetListofCrates = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/products/crates?where_is_active=${reqObj.activeStatus}&sort_by_capacity=${reqObj.capacity}&sort_by_name=${reqObj.sort_by_name}&sort_by_price=${reqObj.sort_by_price}&sort_by_created_at=${reqObj.sort_by_created_at}&page_size=100`
      );
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_AVAILABLE_CRATE,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

//function for updating new sku crate details
export const UpdateCrateDetails = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/SSM/products/update/crate`, reqObj);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.POST_ADD_NEW_CRATE,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      CustomConsole(error);
    }
  };
};

//function for Linking Crate and Product  details
export const LinkCrateAndProduct = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/SSM/products/link/crate`, reqObj);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.LINK_PRODUCT_CRATE,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      CustomConsole(error);
    }
  };
};

//function for Linking Crate and Product  details
export const DeleteCrateWithProduct = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/SSM/products/remove/crate`, reqObj);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.DELETE_CRATE_PRODUCT,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      CustomConsole(error);
    }
  };
};
