import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useSelector } from "react-redux";
import QRCode from "qrcode";
import { Button } from "@mui/material";
import { appColors } from "../../../Components/Assests/AppTheme/appThemeConst";
import { DownloadSharp } from "@mui/icons-material";

function RenderGiftCards({ templateFileName, renderData }) {
  const [template, setTemplate] = useState("");
  const [sampleCard, setSampleCard] = useState("");
  const imageFiles = useSelector((state) => state.fileHandler.appImages);
  const imageFilesstore = imageFiles[`image_${templateFileName}`];

  console.log("Image Files Store:", imageFilesstore);
  console.log("Render Data:", renderData);

  const getGiftCard = async (data) => {
    // Generate QR Code as Base64
    const qrCodeImage = await QRCode.toDataURL(data.id);
    const amount = data.branch
      ? `<div
          style="
            font-size: 1.2em;
          font-weight: bold;
          margin: 10px 0;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
          "
        >
          ₹ ${data.amount}
        </div>`
      : "";

    const giftCardHTML = template
      .replace("{{amount}}", amount || "")
      .replace("{{branch}}", data?.branch || "")
      .replace("{{id}}", data?.id || "")
      .replace("{{password}}", data?.password || "")
      .replace(
        "{{qr_code_image}}",
        `<img src="${qrCodeImage}" alt="QR Code" />`
      );

    return giftCardHTML;
  };

  // Fetch and decode the template from the store
  useEffect(() => {
    const fetchTemplate = async () => {
      if (imageFilesstore && templateFileName) {
        try {
          const storedImageURL = imageFilesstore;
          if (!storedImageURL) {
            console.error("Template file not found in store.");
            return;
          }

          const base64Content = storedImageURL.split(",")[1]; // Extract Base64 part
          const decodedTemplate = atob(base64Content); // Decode Base64
          setTemplate(decodedTemplate);
        } catch (error) {
          console.error("Error fetching the template:", error);
        }
      }
    };

    fetchTemplate();
  }, [imageFilesstore, templateFileName]);

  // Function to generate a sample gift card
  useEffect(() => {
    const generateSampleCard = async () => {
      if (template && renderData?.length) {
        const sample = await getGiftCard(renderData[0]);
        setSampleCard(sample);
      }
    };
    generateSampleCard();
  }, [template, renderData]);

  // Function to generate PDF with multiple pages
  const generatePDF = async () => {
    if (!template) {
      console.error("Template not loaded");
      return;
    }
    const pdf = new jsPDF();
    for (let i = 0; i < renderData?.length; i++) {
      const data = renderData[i];

      // Generate the gift card HTML
      const giftCardHTML = await getGiftCard(data);

      // Create a div to render the HTML template
      const div = document.createElement("div");
      div.innerHTML = giftCardHTML;
      document.body.appendChild(div);

      // Convert the HTML to canvas
      const canvas = await html2canvas(div);

      // Add the image to the PDF
      if (i > 0) pdf.addPage(); // Add a new page for each gift card
      pdf.addImage(canvas.toDataURL("image/png"), "PNG", 10, 10);

      // Remove the div element after rendering
      document.body.removeChild(div);
    }

    // Save the generated PDF
    pdf.save("gift_cards.pdf");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      {/* Render sample card */}
      {sampleCard && (
        <div
          dangerouslySetInnerHTML={{ __html: sampleCard }}
          style={{ marginRight: "20px" }}
        ></div>
      )}
      <Button
        onClick={generatePDF}
        sx={{ background: appColors.blueColor, marginTop: ".5rem" }}
        variant="contained"
        size="small"
      >
        <DownloadSharp/> Gift Cards PDF
      </Button>
    </div>
  );
}

export default RenderGiftCards;
