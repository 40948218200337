import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Card,
  CardContent,
} from "@mui/material";

export default function ComponentListDialog({ open, setOpen, componentData }) {
  console.log(componentData)
  return (
    <>
      <Dialog open={open}>
        <DialogTitle>Component List</DialogTitle>
        <DialogContent>
          <Box sx={{}}>
            {componentData?.map((data) => (
              <Card sx={{ width: "100%" }}>
                <CardContent>
                  <Box sx={cardStyles.mainBox}>
                    <Box sx={cardStyles.manuFactureDetails}>
                      <Typography sx={cardStyles.typoHeader}>
                        Component Name :{" "}
                        <span style={cardStyles.spanText}>
                          {`${data?.capacity}_${data?.material}`}
                        </span>
                      </Typography>
                      <Typography sx={cardStyles.typoHeader}>
                        Quantity :{" "}
                        <span style={cardStyles.spanText}>
                          {data?.quantity}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
const cardStyles = {
  boxContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: 2,
  },
  mainBox: {
    display: "flex",
    flexDirection: "column",
  },
  manuFactureDetails: {
    fontSize: "1.2rem",
    fontWeight: 500,
    textAlign: "start",
  },
  typoHeader: { fontSize: "1rem", fontWeight: "Bold" },
  spanText: { fontSize: ".9rem", fontWeight: "500" },
};
