import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTable from "../../../Components/Tables/CoustomTable";
import { tabelConfig } from "../../../Components/AppMeta/appTabelConfig";
import {
  CreatePhysicalGiftCard,
  GetGiftCarData,
  GetPhysicalGiftCards,
} from "../../Redux/Actions/AdminSettingsAction";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { useDispatch } from "react-redux";
import CoustomAddItemsDialog from "../../../Components/Custom/addItemsDialog";
import { textFieldConfig } from "../../../Components/AppMeta/appTextFieldConfig";
import PrimaryButton from "../../../Components/Buttons/primaryButton";
import { appColors } from "../../../Components/Assests/AppTheme/appThemeConst";
import GiftCardBatchDetails from "./GiftCardBatchDetails";
import RenderGiftCard from "./RenderGiftCard";
import { GIFTCARD_STATUS } from "../../../Components/AppMeta/appMetaConfig";
import useProductImages from "../../../Components/Custom/useProductImages";

function PhysicalGiftCardMain() {
  const dispatch = useDispatch();
  const [giftCards, setGiftCards] = useState([]);
  const [isEditButon, setIsEditButton] = useState(false);
  const [giftCardSetting, setGiftCardSetting] = useState({});
  const [formValues, setFormValues] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [fieldValidity, setFieldValidity] = useState({});
  const [SelectedGiftCard, setSelectedGiftCard] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);


  const tableRowClickFunction = () => {};
  const getGiftcards = () => {
    dispatch(
      GetPhysicalGiftCards({
        pageNumber: pageNumber,
        pageSize: pageSize,
      })
    ).then((data) => {
      setGiftCards(data?.data);
    });
  };
  useEffect(() => {
    getGiftcards();
  }, [pageNumber, pageSize]);

  //usefeect to get admin setings
  useEffect(() => {
    dispatch(GetGiftCarData()).then((data) => {
      setGiftCardSetting(data?.data?.gift_card_settings);
    });
  }, []);

  const getStatus = (props) => {
    let status_text;
    console.log(props?.row?.status);
    switch (props?.row?.details?.status) {
      case GIFTCARD_STATUS.ACTIVE:
        status_text = "Active";
        break;
      case GIFTCARD_STATUS.COMPLETED:
        status_text = "Completed";
        break;
      case GIFTCARD_STATUS.DEACTIVE:
        status_text = "Inactive";
        break;
      case GIFTCARD_STATUS.PAYMENTCLOSE:
        status_text = "Payment Closed";
        break;
      case GIFTCARD_STATUS.PRINTING:
        status_text = "Printing";
        break;

      default:
        break;
    }
    return <Box sx={{ display: "flex", gap: 1 }}>{status_text}</Box>;
  };
  const handelEditButton = (props) => {
    setIsEditButton(true);

    const { row } = props;
    setSelectedGiftCard(row);
  };
  const EditActionButtom = (props) => {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        <EditIcon
          onClick={() => {
            handelEditButton(props);
          }}
        />
      </Box>
    );
  };
  const submitCreateGc = (props) => {
    dispatch(CreatePhysicalGiftCard(props)).then((data) => {
      if (!data.error) {
        getGiftcards();
        setOpenDialog(false);
      }
    });
  };
  const GiftCardSubmitBtn = (props) => {
    return (
      <Box>
        <PrimaryButton
          btnName="Submit"
          handleButtonClick={() => {
            submitCreateGc(props);
            // handelSubmitManufacture(props)
          }}
          color={appColors.commonBtnColor}
        />
      </Box>
    );
  };
  return (
    <Box>
      <CustomTable
        tableHead={tabelConfig.PhysicalGiftCardTable.headerData}
        tableData={giftCards}
        tableDataKey={[
          ...tabelConfig.PhysicalGiftCardTable.tabelDataKey,
          {
            component: (props) => {
              return getStatus(props);
            },
          },
          {
            component: (props) => {
              return EditActionButtom(props);
            },
          },
        ]}
        addButton={true}
        addButtonName="Add GiftCard"
        handleOnClickAddButton={() => setOpenDialog(true)}
        tableRowClickFunction={tableRowClickFunction}
        pagination="200"
        setPageSize={setPageSize}
        setPageNumber={setPageNumber}
        pageNumber={pageNumber || 0}
        pageSize={pageSize}
      />
      <CoustomAddItemsDialog
        openDialog={openDialog}
        handelClose={() => setOpenDialog(false)}
        dialogTittle="Add Physical Gift Card"
        fieldsConfig={textFieldConfig.addPhysicalGiftCard}
        submitButton={GiftCardSubmitBtn}
        formValues={formValues}
        setFormValues={setFormValues}
        fieldValidity={fieldValidity}
        setFieldValidity={setFieldValidity}
      />
      <GiftCardBatchDetails
        handleClose={() => {
          getGiftcards();
          setSelectedGiftCard(false);
        }}
        giftCardId={SelectedGiftCard}
        open={SelectedGiftCard ? true : false}
        giftCardSetting={giftCardSetting}
      />
    </Box>
  );
}

export default PhysicalGiftCardMain;
