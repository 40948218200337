/**
 * @author vinay kumar
 * @description Deliveries Filter Dialog
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 26-06-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import * as Material from "@mui/material";
import { Box, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import SampleButton from "../../Buttons/SampleButton";

import { GetViewOrderDetails } from "../../../Pages/Redux/Actions/ordersAdminAction";
import * as IconName from "react-icons/fc";
import { ADMIN_ROLES } from "../../AppMeta/appMetaConfig";
import CustomConsole from "../../../CustomConsole";
function DeliveriesFilterDialog(props) {
  CustomConsole(props);
  const [orderId, setOrderId] = React.useState("");
  const handleClose = () => {
    CustomConsole("----handleClose------");
    // props.setFilterDialogOpen(false);
    props.handleClose(props);
  };

  const [deliveryBookingTime, setDeliveryBookingTime] = React.useState("");
  const [deliverySlot, setDeliverySlot] = React.useState("");
  const [orderType, setOrderType] = React.useState("");
  const [pincode, setPincode] = React.useState(0);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [assignedOrUnAssigned, setSssignedOrUnAssigned] = React.useState("");
  const [deliveryAgent, setDeliveryAgent] = React.useState("");
  const [adminRole, setAdminRoleId] = React.useState("");

  const [sortBookingTimeandDeliverySlot, setSortBookingTimeandDeliverySlot] =
    React.useState("");
  CustomConsole(deliveryAgent);
  React.useEffect(() => {
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
  }, []);

  const handleChangeDeliverybookingtime = (event) => {
    setDeliveryBookingTime(event.target.value);
  };

  const handleChangeOrdertypetofilter = (event) => {
    setOrderType(event.target.value);
  };

  const handleChangeDeliverySlotSelected = (event) => {
    setDeliverySlot(event.target.value);
  };

  const handleSortBookingTimeandDeliverySlot = (event) => {
    setSortBookingTimeandDeliverySlot(event.target.value);
  };

  const handleClickApply = (event) => {
    CustomConsole("---------handleClickApply--------");
    event.preventDefault();
    props.handleClickApply({
      enter_Pincode: pincode,
      // agent_assigned: assignedOrUnAssigned,
      // phone: phoneNumber,
      // select_Booking_Time: deliveryBookingTime,
      // select_Delivery_slot: deliverySlot,
      // order_Type: orderType === 10 ? null : orderType,
      // delivery_agent_id: deliveryAgent,
    });
    setPincode("");
    setDeliverySlot("");
    setDeliveryBookingTime("");
    setOrderType("");
    setSortBookingTimeandDeliverySlot("");
  };

  const handleClearFilter = () => {
    CustomConsole("---------handleClearFilter--------");
    setPincode("");
    setDeliverySlot("");
    setDeliveryBookingTime("");
    setOrderType("");
    setSortBookingTimeandDeliverySlot("");
    props.handleClickApply({
      phone: "",
      enter_Pincode: "",
      agent_assigned: "",
      select_Booking_Time: "",
      select_Delivery_slot: "",
      order_Type: "",
    });
  };
  const handleSearch = () => {
    CustomConsole("------------handleSearchOrder------------");
    props.handleSearch({ OrderId: orderId });
  };
  const handleChangePhone = (event) => {
    setPhoneNumber(event.target.value);
    if (event.target.value) {
      setSssignedOrUnAssigned(true);
    }
  };

  return (
    <Box>
      <Dialog open={props.filterDialogOpen}>
        {/* <DistributorSearchOrderCard handleSearch={handleSearchOrder} /> */}
        {/* {props.CompletedDelivery ? (
          ""
        ) : (
          <Material.Box sx={{ margin: "10px" }}>
            <Material.TextField
              //   sx={{ borderRadius: "30px" }}
              label="Search Order"
              variant="outlined"
              value={orderId}
              onChange={(event) => setOrderId(event.target.value)}
              InputProps={{
                startAdornment: (
                  <Material.InputAdornment position="start">
                    <Material.Typography sx={{ fontWeight: "bold" }}>
                      BTORD-
                    </Material.Typography>
                  </Material.InputAdornment>
                ),
                endAdornment: (
                  <Material.InputAdornment position="end">
                    <IconName.FcSearch size="30px" onClick={handleSearch} />
                  </Material.InputAdornment>
                ),
              }}
            />
          </Material.Box>
        )} */}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <DialogTitle sx={{ alignItems: "center" }} id="alert-dialog-title">
            {"Apply Filter"}
          </DialogTitle>
        </Box>
        <DialogContent>
          {/* {ADMIN_ROLES.BW_DISTRIBUTER === adminRole ? (
            <Box sx={{ marginBottom: "20px" }}>
              <FormControl fullWidth>
                <InputLabel>Select Delivery Agent</InputLabel>
                <Select
                  value={deliveryAgent}
                  label="Select Delivery Agent"
                  onChange={(event) => setDeliveryAgent(event.target.value)}
                >
                  {props.agentListata.map((data) => {
                    return (
                      <MenuItem value={data.driver_id}>
                        {data.driver_profile.fullname}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          ) : (
            ""
          )} */}
          {/* {ADMIN_ROLES.BW_DISTRIBUTER === adminRole ? (
            <Material.Typography>
              Select Assign/UnAssign Delivery Agent
            </Material.Typography>
          ) : (
            ""
          )} */}
          {/* {ADMIN_ROLES.BW_DISTRIBUTER === adminRole ? (
            <Box sx={{ margin: "10px" }}>
              <FormControl>
                <RadioGroup
                  value={assignedOrUnAssigned}
                  onChange={(event) =>
                    setSssignedOrUnAssigned(event.target.value)
                  }
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Assigned"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Un Assigned"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          ) : (
            ""
          )} */}
          <Box>
            <TextField
              fullWidth
              label="Enter Pincode"
              value={pincode}
              type="number"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 6);
              }}
              onChange={(event) => setPincode(event.target.value)}
            />
          </Box>
          {/* {props.CompletedDelivery ? (
            ""
          ) : ( */}

          {/* )} */}
          {/* {props.CompletedDelivery ? (
            ""
          ) : ( */}
          {/* {ADMIN_ROLES.BW_DISTRIBUTER === adminRole ? (
            <Box sx={{ paddingTop: "10px" }}>
              <TextField
                fullWidth
                label="Driver Phone Number"
                value={phoneNumber}
                type="number"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                onChange={handleChangePhone}
              />
            </Box>
          ) : (
            ""
          )} */}
          {/* )} */}
          {/* <Box>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={sortBookingTimeandDeliverySlot}
                onChange={handleSortBookingTimeandDeliverySlot}
              >
                <FormControlLabel
                  value="sortBookingtime"
                  control={<Radio />}
                  label="Sort according to booking time"
                />
                <FormControlLabel
                  value="sortDeliverySlotSelected"
                  control={<Radio />}
                  label="Sort according to Delivery slot selected"
                />
              </RadioGroup>
            </FormControl>
          </Box> */}
          {/* {sortBookingTimeandDeliverySlot === "sortBookingtime" ? (
            <Box sx={{ margin: "10px" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label5">
                  Select Delivery booking time
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label6"
                  id="demo-simple-select1"
                  value={deliveryBookingTime}
                  label="Delivery booking time"
                  onChange={handleChangeDeliverybookingtime}
                >
                  <MenuItem value={"ASC"}>Ascending Orders</MenuItem>
                  <MenuItem value={"DESC"}>Descending Orders</MenuItem>
                </Select>
              </FormControl>
            </Box>
          ) : (
            ""
          )} */}
          {/* {sortBookingTimeandDeliverySlot === "sortDeliverySlotSelected" ? ( */}
          {/* <Box sx={{ margin: "10px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label1">
                Select Delivery Slot Selected
              </InputLabel>
              <Select
                labelId="demo-simple-select-label2"
                id="demo-simple-select2"
                value={deliverySlot}
                label="Select Delivery Slot"
                onChange={handleChangeDeliverySlotSelected}
              >
                <MenuItem value={"ASC"}>Ascending Orders</MenuItem>
                <MenuItem value={"DESC"}>Descending Orders</MenuItem>
              </Select>
            </FormControl>
          </Box> */}
          {/* ) : (
            ""
          )} */}
          {/* <Box>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label3">
                Select Order type to filter
              </InputLabel>
              <Select
                labelId="demo-simple-select-label4"
                id="demo-simple-select3"
                value={orderType}
                label="Select Order Type"
                onChange={handleChangeOrdertypetofilter}
              >
                <MenuItem value={10}>All Orders</MenuItem>
                {props.currentDayDeliveries ? (
                  <MenuItem value={200}>Insta Delivery Orders</MenuItem>
                ) : (
                  ""
                )}
                <MenuItem value={100}>Subscription Orders</MenuItem>
                <MenuItem value={300}>PostPaid Orders</MenuItem>
                <MenuItem value={400}>Regular Orders</MenuItem>
              </Select>
            </FormControl>
          </Box> */}
        </DialogContent>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              sx={{ margin: "20px" }}
              onClick={handleClearFilter}
            >
              clear filter
            </Button>

            <Button
              variant="contained"
              color="error"
              sx={{ margin: "20px" }}
              onClick={handleClose}
            >
              {" "}
              Close
            </Button>
            <Button
              variant="contained"
              sx={{ margin: "20px" }}
              onClick={handleClickApply}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}

export default DeliveriesFilterDialog;
