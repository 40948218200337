import React from "react";
import * as Material from "@mui/material";
import SupplierDriversList from "../AdminUsers/SupplierDriversList";
import { employeeType } from "../../Components/AppMeta/appMetaConfig";
import CustomConsole from "../../CustomConsole";

function SupplierDriverDetailsDialog(props) {
  const handleCloseDriverDetails = () => {
    props.handleCloseDriverDetails();
  };
  return (
    <Material.Dialog open={props.openDriversDetailsDialog}>
      <Material.DialogContent>
        <SupplierDriversList
          supplierIntegerId={props.supplierIntegerId}

          supplierId={props.supplierId}
          operationType={{
            name: "Driver",
            plural: "Drivers",
            employee_type: employeeType.DRIVER,
          }}
          employee_type={employeeType.DRIVER}
        />
      </Material.DialogContent>
      <Material.DialogActions>
        <Material.Button
          color="error"
          variant="contained"
          onClick={handleCloseDriverDetails}
        >
          close
        </Material.Button>
      </Material.DialogActions>
    </Material.Dialog>
  );
}

export default SupplierDriverDetailsDialog;
