import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useState, useEffect } from "react";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function ManufactureOrderTabel({
  columnRowData,
  tabelData,
  rowsPerPage,
  handleChangePage,
  pageNumber,
  handleChangeRowsPerPage,
  SelectOptions,
}) {
  const [open, setOpen] = useState("");
  const [openFlag, setOpenFlag] = useState(false);
  const data = [];
  //function for handling view icon
  const handelIcon = (index) => {
    setOpen(index);
    setOpenFlag(!openFlag);
  };
  console.log("tabel data render details");
  console.log(columnRowData);
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {SelectOptions()}
        <TablePagination
          rowsPerPageOptions={[25, 100, 500]}
          component="div"
          count={tabelData?.length}
          rowsPerPage={rowsPerPage}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <TableContainer sx={{ maxHeight: 440, width: "100%" }}>
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead>
            <TableRow sx={appColors.commonLayoutColor}>
              {columnRowData?.map((column, idx) => (
                <TableCell key={idx} align={column.align || "left"}>
                  <strong>{column.header}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tabelData
              ?.slice(
                pageNumber * rowsPerPage,
                rowsPerPage > 0
                  ? pageNumber * rowsPerPage + rowsPerPage
                  : tabelData.length
              )
              ?.map((row, rowIndex) => (
                <>
                  <TableRow
                    sx={{ "& > *": { borderBottom: "unset" } }}
                    key={rowIndex}
                  >
                    {columnRowData?.map((column, colIndex) => (
                      <TableCell key={colIndex} align={column.align || "left"}>
                        {column.render
                          ? column.render(row[column.field], row, rowIndex)
                          : row[column.field]}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={12}
                    >
                      {/* <Collapse in={open} timeout="auto" unmountOnExit > */}
                      {open === rowIndex && openFlag === true ? "" : ""}
                      {/* </Collapse> */}
                    </TableCell>
                  </TableRow>
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
