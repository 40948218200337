import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import CustomConsole from "../../CustomConsole";
import Select from "@mui/material/Select";
import moment from "moment";
import Refresh from "@mui/icons-material/Refresh";
import * as MuiIcons from "@mui/icons-material";
import {
  Button,
  Card,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  AssignDialogAdmin,
  DamageCansData,
  GetWithdrawalDistributorDetails,
  GetWithdrawallist,
  getWithdrawallist,
  GetWithdrawallistOrderid,
  GetWithrawalDetailsAccept,
  PostWithdrawalDetailsrefund,
  PutWithdrawalDetailsReject,
} from "../Redux/Actions/AdminWithdrawalListAction";
import { useDispatch, useSelector } from "react-redux";
import { useSelect } from "@mui/base";
import DownloadIcon from "@mui/icons-material/Download";
import { CSVLink } from "react-csv";
import { returnCanOrderStatus } from "../../Components/AppMeta/appMetaConfig";
import Stack from "@mui/material/Stack";
import "../Common/drawer.css";
import ReturnCanFilter from "../../Components/Dialog/ReturnCanFilter";
import ReturnCanParticularOrderDialog from "../../Components/Dialog/ReturnCanParticularOrderDialog";
import DistributorDetailsDialog from "./DistributorDetailsDialog";
import AccceptRejectCard from "./AccceptRejectCard";
import { GetWFSDetails } from "../Redux/Actions/adminEmptyCanOrderAction";
import {
  AssignSupplier,
  GetViewOrderDetails,
} from "../Redux/Actions/ordersAdminAction";
export default function AdminWithdrawalOrderList({
  withdrawalOrderlist,
  withdrawalOrderDetails,
  setWithdrawalOrderDetails,
  withdrawaOrderDetails,
  scroll,
  onClickScroll,
  returnCanOrderId,
  setReturnCanOrderId,
  setdialogFlag,
  issueDialog,
  setIssueFlag,
  pageNumber,
  pageSize,
  setPageSize,
  setPageNumber,
  setChangeMinusBtn,
  setChangePlusBtn,
  pageStatus,
  setPageStatus,
  pageChange,
  setPageChange,
  adminRole,
  ADMIN_ROLES,
}) {
  const dispatch = useDispatch();
  const adminWithdrawallistData = useSelector(
    (state) => state.withdrawalOrderlistAdmin
  );
  const orderDetailsData = useSelector((state) => state.orderDetailsAdmin);
  CustomConsole(orderDetailsData);
  const [listOrderBtn, setListOrderBtn] = React.useState(false);
  const [listOrderFlag, setListOrderFlag] = React.useState(false);
  const [statuss, setStatuss] = React.useState(false);
  const [searchOrderId, setSearchOrderId] = React.useState("");
  const [openFilterDialog, setOpenFilterDialog] = React.useState(false);
  const [testArr, setTestArr] = React.useState([]);
  const [withdrawDet, setWithDrawDet] = React.useState({});
  const [userId, setUserId] = React.useState("");
  const [supplierID, setSupplierID] = React.useState("");
  CustomConsole(supplierID);
  const [isConsumer, setIsConsumer] = React.useState("");
  CustomConsole(isConsumer);
  const [orderId, setOrderId] = React.useState("");
  const [filterOrderId, setFilterOrderId] = React.useState("");
  CustomConsole(orderId);
  // const [orderStatus, setOrderStatus] = React.useState("");
  const [orderStatus, setOrderStatus] = React.useState("");
  CustomConsole(orderStatus);
  // const [returnOrderId, setReturnCanOrderId] = React.useState("");
  const [openDialogReturnCanSingleOrder, setOpenDialogReturnCanSingleOrder] =
    React.useState(false);
  const [singleData, setSingleData] = React.useState("");
  CustomConsole(singleData);
  const [distributorDetailsFlag, setDistributorDetailsFlag] =
    React.useState(false);
  // const [changeMinusBtn, setChangeMinusBtn] = React.useState(false);
  // const [changePlusBtn, setChangePlusBtn] = React.useState(false);
  // const [pageStatus, setPageStatus] = React.useState(false);
  // const [pageChange, setPageChange] = React.useState(false);


  // React.useEffect(() => {
  //   CustomConsole("------------Page Size---------");
  //   dispatch(
  //     GetWithdrawallist({
  //       page_number: pageNumber - 1,
  //       page_size: pageSize,
  //       order_status: orderStatus,
  //     })
  //   );
  // }, [pageSize]);

  React.useEffect(() => {
    // CustomConsole(adminWithdrawallistData)
    if (
      listOrderFlag === true &&
      adminWithdrawallistData.withdrawalOrderid &&
      Object.keys(adminWithdrawallistData.withdrawalOrderid).length > 0
    ) {
      adminWithdrawallistData.withdrawalOrderid.map((data) => {
        CustomConsole(data);
        setWithdrawalOrderDetails(data);
      });
    }
  }, [adminWithdrawallistData]);

  React.useEffect(() => {
    CustomConsole(userId);
    if (userId && userId !== "") {
      dispatch(DamageCansData(userId));
    }
  }, [userId]);

  React.useEffect(() => {
    CustomConsole(withdrawaOrderDetails);
  }, [withdrawaOrderDetails]);

  const handleCloseDetailDialog = () => {
    setOpenDialogReturnCanSingleOrder(false);
  };

  const clickOrderlist = (orderId, status, data) => {
    CustomConsole(data);
    CustomConsole(orderId);
    setSingleData(data);
    setOrderId(data.return_can_order_id);
    setIsConsumer(data.is_consumer);
    setOpenDialogReturnCanSingleOrder(true);
    setReturnCanOrderId(orderId);
    setListOrderBtn(true);
    scroll();
    if (status === returnCanOrderStatus.PENDING) {
      setdialogFlag(true);
    }
    if (status === returnCanOrderStatus.CONSUMER_ACCEPTED) {
      setIssueFlag(true);
    }
    // setTimeout(() => {
    //   onClickScroll();
    // }, 500);
  };
  React.useEffect(() => {
    if (listOrderBtn === true) {
      dispatch(GetWithdrawallistOrderid(returnCanOrderId));
      setListOrderFlag(true);
      setListOrderBtn(false);
    }
  }, [listOrderBtn]);

  const handleViewDetails = () => {
    // dispatch(GetWithdrawallistOrderid("WTHDRWL" + searchOrderId));
    // setListOrderBtn(true);
    // scroll();
    // setListOrderFlag(true);

    setOpenDialogReturnCanSingleOrder(true);
    setSearchOrderId("");
  };

  const handleFilter = (props) => {
    CustomConsole(props);
    // setOrderStatus(props.order_status);
    CustomConsole("------------Filter---------");
    dispatch(
      GetWithdrawallist({
        page_number: pageNumber - 1,
        page_size: pageSize,
        order_status: orderStatus,
        return_can_order_id: filterOrderId,
      })
    );
    setOpenFilterDialog(false);
    setPageNumber(1);
    setPageSize(25);
  };
  const handleClose = () => {
    setOpenFilterDialog(false);
  };

  React.useEffect(() => {
    if (pageChange - 1) {
      dispatch(
        GetWithdrawallist({
          page_number: pageNumber - 1,
          page_size: pageSize,
          order_status: orderStatus,
        })
      );
      setChangeMinusBtn(true);
      setChangePlusBtn(true);
    }
  }, [pageNumber]);
  CustomConsole(pageNumber);
  CustomConsole("-----------f");

  React.useEffect(() => {
    if (adminWithdrawallistData.withdrawalList.length > 0) {
      setPageStatus(false);
    } else {
      setPageStatus(true);
    }
  });
  React.useEffect(() => {
    CustomConsole("----------- withdrawalOrderDetails -------------------");
    CustomConsole(adminWithdrawallistData);
    CustomConsole(singleData.user_id);
    // setReturnOrderId(adminWithdrawallistData.return_can_order_id);
    setUserId(singleData.user_id);
    setWithDrawDet(withdrawalOrderDetails);
  }, [adminWithdrawallistData]);

  React.useEffect(() => {
    setWithDrawDet(withdrawalOrderDetails);
  }, [withdrawalOrderDetails]);

  const handlePageChange = (event, value) => {
    setPageNumber(value);
    setPageChange(true);
  };

  const handleChangeMinus = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
    setChangeMinusBtn(true);
  };

  const handleChangePlus = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber + 1);
    }
    setChangePlusBtn(true);
  };

  const clickRejected = (props) => {
    CustomConsole("-------clickRejecteded-------");
    CustomConsole(props);
    dispatch(PutWithdrawalDetailsReject(props));
    setTimeout(() => {
      dispatch(GetWithdrawallistOrderid(props));
      dispatch(
        GetWithdrawallist({
          page_number: pageNumber - 1,
          page_size: pageSize,
          order_status: orderStatus,
        })
      );
    }, 1500);
    setOpenDialogReturnCanSingleOrder(false);
    setDistributorDetailsFlag(false);
  };
  const ClickAcceptFirst = (props) => {
    CustomConsole("-----------------ClickAcceptFirstOpen---------------");
    CustomConsole(props);
    // setReturnCanOrderId(props);
    setDistributorDetailsFlag(true);
    // setFlag and Hit the API,
    dispatch(GetWithrawalDetailsAccept(props, pageNumber - 1));
    dispatch(GetWithdrawalDistributorDetails(props));
  };
  // const ClickReAssign = (props) => {
  //   CustomConsole("-----------------ClickAcceptFirstOpen---------------");
  //   CustomConsole(props);
  //   // setReturnCanOrderId(props);
  //   setDistributorDetailsFlag(true);
  //   // setFlag and Hit the API,
  //   dispatch(GetWithrawalDetailsAccept(props, pageNumber - 1));
  //   dispatch(GetWithdrawalDistributorDetails(props));
  // };

  const orderStatusFun = (order_status) => {
    CustomConsole(order_status);
    switch (order_status) {
      case returnCanOrderStatus.CONSUMER_ACCEPTED:
        return "CONSUMER_ACCEPTED";
      case returnCanOrderStatus.APPROVED:
        return "ONGOING";
      case returnCanOrderStatus.REFUND:
        return "REFUND";
      case returnCanOrderStatus.DELIVERED:
        return "DELIVERED";
      case returnCanOrderStatus.REJECTED:
        return "CANCELLED";
      case returnCanOrderStatus.PENDING:
        return "PENDING";
      default:
        return "";
    }
  };
  const handleCloseDialog = () => {
    setDistributorDetailsFlag(false);
  };

  const handleAssignSupplier = (props) => {
    CustomConsole("-----------handleAssignSupplier-------------");
    CustomConsole(props);
    CustomConsole(returnCanOrderId);
    const reqobj = {
      user_id: props.supplierId,
      order_id: returnCanOrderId,
    };
    CustomConsole(reqobj);
    dispatch(AssignSupplier(reqobj));
    // if (props.orderStatus === 0) {
    //   CustomConsole(
    //     isConsumer === false ? parseInt(props.supplierId) : props.supplierId
    //   );
    //   dispatch(
    //     AssignDialogAdmin(
    //       isConsumer === false ? parseInt(props.supplierId) : props.supplierId,
    //       returnCanOrderId
    //     )
    //   );
    // } else {
    //   const reqobj = {
    //     user_id: props.supplierId,
    //     order_id: returnCanOrderId,
    //   };
    //   CustomConsole(reqobj)
    //   dispatch(AssignSupplier(reqobj));
    // }
    // CustomConsole(supplierId);
    // CustomConsole(returnCanOrderId);
    // setSupplierID(supplierId);
    // CustomConsole(isConsumer === false ? parseInt(supplierId) : supplierId);
    // dispatch(
    //   AssignDialogAdmin(
    //     isConsumer === false ? parseInt(supplierId) : supplierId,
    //     returnCanOrderId
    //   )
    // );
    setOpenDialogReturnCanSingleOrder(false);
    setDistributorDetailsFlag(false);
    setTimeout(() => {
      dispatch(GetWithdrawallistOrderid(returnCanOrderId));
      dispatch(
        GetWithdrawallist({
          page_number: pageNumber - 1,
          page_size: pageSize,
          order_status: orderStatus,
        })
      );
    }, 1500);
  };

  const ClickDistributor = (orderId) => {
    CustomConsole(orderId);
    // dispatch(GetWithdrawalDistributorDetails(orderId))
  };

  const handelIssueRefund = ({ orderId, refund_amount }) => {
    CustomConsole("------handelIssueRefunde.---------");
    CustomConsole(orderId);
    dispatch(PostWithdrawalDetailsrefund({ orderId, refund_amount }));
    setDistributorDetailsFlag(false);
    setTimeout(() => {
      dispatch(GetWithdrawallistOrderid(orderId));
      dispatch(
        GetWithdrawallist({
          page_number: pageNumber - 1,
          page_size: pageSize,
          order_status: orderStatus,
        })
      );
    }, 1500);
    setOpenDialogReturnCanSingleOrder(false);
  };

  const handleSearchPageSize = () => {
    dispatch(
      GetWithdrawallist({
        page_number: pageNumber - 1,
        page_size: pageSize,
        order_status: orderStatus,
      })
    );
  };

  const handleChangePageSize = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setPageSize(() => event.target.value);
  };

  let result = [];
  function toCSV(items) {
    if (items) {
      CustomConsole(items);
      items.map((a) => {
        CustomConsole(a);
        result.push({
          order_id: a.return_can_order_id,
          consumer_name: a.consumer_name,
          consumer_phone: a.consumer_phone,
          order_status: orderStatusFun(a.order_status),
          product: a.container_capacity + "-" + a.container_material,
          container_qty: a.container_qty,
          service_area:
            a.consumer_address !== undefined &&
            a.consumer_address !== null &&
            a.consumer_address.service_area != undefined
              ? a.consumer_address.service_area
              : "--",
          pincode:
            a.consumer_address !== undefined &&
            a.consumer_address !== null &&
            a.consumer_address.pincode != undefined
              ? a.consumer_address.pincode
              : "--",
          address:
            a.consumer_address !== undefined &&
            a.consumer_address !== null &&
            a.consumer_address.address_line1 != undefined &&
            a.consumer_address.address_line2 != undefined
              ? a.consumer_address.address_line1 +
                a.consumer_address.address_line2
              : "--",
          return_can_otp: a.return_can_otp,
          supplier_name: a.supplier_name,
          delivery_agent_name:
            a.delivery_agent_name !== undefined ? a.delivery_agent_name : "---",
          refund_amount: a.refund_amount,
          created_at: a.created_at,
          updated_at: a.updated_at,
        });
        // result.push(a);
      });
    }
    setTestArr(result);
  }
  const csvLink = {
    filename: "Report",
    data: testArr,
  };

  //function to clear filter
  const handelClearFilter = () => {
    setOrderStatus("");
    setPageNumber(1);
    setPageSize(25);
    setFilterOrderId("");
    handleClose();
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "start" }}>
          {/* <TextField
            label="Search By Order Id"
            value={searchOrderId}
            onChange={(event) => setSearchOrderId(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{ fontWeight: "bold", color: "black" }}
                  >
                    WTHDRWL
                  </Typography>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconName.FcSearch
                    cursor="pointer"
                    size="30px"
                    onClick={handleViewDetails}
                  />
                </InputAdornment>
              ),
              style: { borderRadius: "50px" },
            }}
          /> */}
          {/* <Box sx={{ margin: "10px" }}>
            <Button
              variant="contained"
              size="small"
              // height="15px"
              sx={{
                backgroundColor: "black",
                border: "1px solid #1FA6F9",
                "&:hover": {
                  backgroundColor: "#D1E9F7",
                  color: "black",
                  marginLeft: "10px",
                },
              }}
              startIcon={<FilterAltIcon />}
              onClick={() => setOpenFilterDialog(!openFilterDialog)}
            >
              Filter
            </Button>
          </Box> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: { md: "end", xs: "center" },
            flexWrap: "wrap",
          }}
        >
          <Box sx={{ margin: "10px" }}>
            <Button
              variant="contained"
              size="small"
              // height="15px"
              sx={{
                backgroundColor: "black",
                // border: "1px solid #1FA6F9",
                // "&:hover": {
                //   backgroundColor: "#D1E9F7",
                //   color: "black",
                //   marginLeft: "10px",
                // },
              }}
              startIcon={<FilterAltIcon />}
              onClick={() => setOpenFilterDialog(!openFilterDialog)}
            >
              Filter
            </Button>
          </Box>
          <Box sx={{ maxWidth: "120px", maxHeight: "10px", margin: "5px" }}>
            <TextField
              size="small"
              value={pageSize}
              label="Page size"
              // onChange={(e) => {
              //   setPageSize(e.target.value);
              //   // setSearchOrderFlag(true);
              // }}

              InputProps={{
                maxLength: 10, // Optional: You can set the maximum length if needed
                onInput: handleChangePageSize, // Attach the input event handler
                endAdornment: (
                  <InputAdornment position="end">
                    <MuiIcons.Search
                      cursor="pointer"
                      size="30px"
                      onClick={handleSearchPageSize}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", margin: 1 }}>
            <Button onClick={handleChangeMinus}>
              <ArrowBackIos />
            </Button>
            <Typography
              variant="button"
              display="block"
              gutterBottom
              sx={{
                marginLeft: "10px",
                marginRight: "10px",
                backgroundColor: "#2D81FF",
                color: "white",
                padding: "5px",
                width: "40px",
                height: "35px",
                borderRadius: "15px",
                textAlign: "center",
              }}
            >
              {pageNumber}
            </Typography>
            <Button disabled={pageStatus} onClick={handleChangePlus}>
              <ArrowForwardIos />
            </Button>
            {/* <Box sx={{ margin: 1 }}> */}
            <CSVLink {...csvLink}>
              {" "}
              <DownloadIcon
                sx={{ fontSize: 35 }}
                onClick={() => toCSV(withdrawalOrderlist)}
              />
            </CSVLink>
            {/* </Box> */}
          </Box>
        </Box>
      </Box>
      {/* <Button
          variant="contained"
          size="small"
          height="10px"
          sx={{
            backgroundColor: "black",
            border: "1px solid #1FA6F9",
            "&:hover": {
              backgroundColor: "#D1E9F7",
              color: "black",
            },
          }}
          startIcon={<FilterAltIcon />}
          onClick={() => setOpenFilterDialog(!openFilterDialog)}
        >
          Filter
        </Button> */}
      <Box sx={{ maxWidth: "150Vw" }}>
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "600px",
            overflow: "auto",
            minWidth: { xs: "350px" },
            marginTop: "1em",
          }}
          className="drawer"
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "azure" }}>
                {/* <TableCell
                align="center"
                sx={{ color: "black", fontWeight: "bold" }}
                size="small"
              >
                S.No
              </TableCell> */}
                <TableCell
                  align="center"
                  sx={{ color: "black", fontWeight: "bold" }}
                  size="small"
                >
                  S.no
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontWeight: "bold" }}
                  size="small"
                >
                  Order Id
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontWeight: "bold" }}
                  size="small"
                >
                  User Type
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontWeight: "bold" }}
                  size="small"
                >
                  User Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontWeight: "bold" }}
                  size="small"
                >
                  User Number
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontWeight: "bold" }}
                  size="small"
                >
                  Product
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontWeight: "bold" }}
                  size="small"
                >
                  Status
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontWeight: "bold" }}
                  size="small"
                >
                  Container Qty
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ color: "black", fontWeight: "bold" }}
                  size="small"
                >
                  Service Area
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontWeight: "bold" }}
                  size="small"
                >
                  Pincode
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontWeight: "bold" }}
                  size="small"
                >
                  Address
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontWeight: "bold" }}
                  size="small"
                >
                  Supplier Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontWeight: "bold" }}
                  size="small"
                >
                  OTP
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontWeight: "bold" }}
                  size="small"
                >
                  Delivery Agent Name
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ color: "black", fontWeight: "bold" }}
                  size="small"
                >
                  Created At
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontWeight: "bold" }}
                  size="small"
                >
                  Updated At
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontWeight: "bold" }}
                  size="small"
                >
                  Refund Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {withdrawalOrderlist ? (
                withdrawalOrderlist.map((data, key) => (
                  <TableRow
                    key={data.name}
                    onClick={() =>
                      clickOrderlist(
                        data.return_can_order_id,
                        data.order_status,
                        data
                      )
                    }
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      marginTop: 20,
                      cursor: "pointer",
                      "&:hover": {
                        boxShadow: "none",
                        backgroundColor: "#2196f3",
                      },
                    }}
                  >
                    {/* <TableCell align="center" size="small">
                      {key + 1}{" "}
                    </TableCell> */}
                    <TableCell align="center" size="small">
                      {key + 1}
                    </TableCell>
                    <TableCell align="center" size="small">
                      {data.return_can_order_id}{" "}
                    </TableCell>
                    <TableCell align="center" size="small">
                      {data.is_consumer === true ? "Consumer" : "Distributor"}
                    </TableCell>
                    <TableCell align="center" size="small">
                      {data.consumer_name}
                    </TableCell>
                    <TableCell align="center" size="small">
                      {data.consumer_phone ? data.consumer_phone : "--"}
                    </TableCell>
                    <TableCell align="center" size="small">
                      {data.container_capacity + "-" + data.container_material}
                    </TableCell>
                    <TableCell
                      align="center"
                      size="small"
                      sx={{
                        color:
                          data.order_status === returnCanOrderStatus.REJECTED
                            ? "red"
                            : data.order_status === returnCanOrderStatus.PENDING
                            ? "#F29339"
                            : data.order_status === returnCanOrderStatus.REFUND
                            ? "green"
                            : "black",
                      }}
                    >
                      {orderStatusFun(data.order_status)}{" "}
                    </TableCell>
                    <TableCell align="center" size="small">
                      {data.container_qty}
                    </TableCell>

                    <TableCell align="center" size="small">
                      {data.consumer_address !== undefined &&
                      data.consumer_address !== null &&
                      data.consumer_address.service_area != undefined
                        ? data.consumer_address.service_area
                        : "--"}
                    </TableCell>
                    <TableCell align="center" size="small">
                      {data.consumer_address !== undefined &&
                      data.consumer_address !== null &&
                      data.consumer_address.pincode != undefined
                        ? data.consumer_address.pincode
                        : "--"}
                    </TableCell>
                    <TableCell align="center" size="small">
                      {data.consumer_address !== undefined &&
                      data.consumer_address !== null &&
                      data.consumer_address.address_line1 != undefined &&
                      data.consumer_address.address_line2 != undefined
                        ? data.consumer_address.address_line1 +
                          data.consumer_address.address_line2
                        : "--"}
                    </TableCell>
                    <TableCell align="center" size="small">
                      {data.supplier_name}
                    </TableCell>
                    <TableCell align="center" size="small">
                      {data.return_can_otp}
                    </TableCell>
                    <TableCell align="center" size="small">
                      {data !== undefined &&
                      data !== null &&
                      data.delivery_agent_name !== undefined
                        ? data.delivery_agent_name
                        : "---"}
                    </TableCell>
                    <TableCell align="center" size="small">
                      {data.created_at}
                      {/* {moment
                          .utc(data.created_at)
                          .tz("Asia/Kolkata")
                          .add(5, "hours")
                          .add(30, "minutes")
                          .format("DD-MM-YYYY hh:mm")} */}
                    </TableCell>
                    <TableCell align="center" size="small">
                      {data.updated_at}
                      {/* {moment
                          .utc(data.updated_at)
                          .tz("Asia/Kolkata")
                          .add(5, "hours")
                          .add(30, "minutes")
                          .format("DD-MM-YYYY hh:mm")} */}
                    </TableCell>
                    <TableCell align="center" size="small">
                      {data.refund_amount}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" size="small">
                    Return Can Order Not Found!
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {openFilterDialog ? (
        <ReturnCanFilter
          openFilterDialog={openFilterDialog}
          handleClose={handleClose}
          handleFilter={handleFilter}
          orderId={filterOrderId}
          setOrderId={setFilterOrderId}
          setOrderStatus={setOrderStatus}
          orderStatus={orderStatus}
          handelClearFilter={handelClearFilter}
        />
      ) : (
        <></>
      )}
      <ReturnCanParticularOrderDialog
        openDialogReturnCanSingleOrder={openDialogReturnCanSingleOrder}
        handleClose={handleCloseDetailDialog}
        singleData={singleData}
        clickRejected={clickRejected}
        ClickAcceptFirst={ClickAcceptFirst}
        // ClickReAssign={ClickReAssign}
        handelIssueRefund={handelIssueRefund}
        withdrawDet={withdrawDet}
        adminWithdrawallistData={adminWithdrawallistData}
        setOpenDialogReturnCanSingleOrder={setOpenDialogReturnCanSingleOrder}
        pageNumber={pageNumber}
        adminRole={adminRole}
        ADMIN_ROLES={ADMIN_ROLES}
        isConsumer={isConsumer}
      />
      {distributorDetailsFlag === true &&
      adminWithdrawallistData.withdrawalDetailsAccept.length ? (
        <DistributorDetailsDialog
          singleData={singleData}
          setDistributorDetailsFlag={setDistributorDetailsFlag}
          distributorDetailsFlag={distributorDetailsFlag}
          distDetails={adminWithdrawallistData.withdrawalDetailsAccept}
          handleCLose={handleCloseDialog}
          handleAssignSupplier={handleAssignSupplier}
          ClickDistributor={ClickDistributor}
          distDialogDetailsData={
            adminWithdrawallistData.withdrawalDetailsSecondAccept
          }
          isConsumer={isConsumer}
          returnCanOrderId={returnCanOrderId}
        />
      ) : (
        CustomConsole("---Condition false---")
      )}
      {CustomConsole("----------------singleData-----------------------")}
      {CustomConsole(singleData)}
      {/* {CustomConsole(adminWithdrawallistData.damageCansData)}
      {adminWithdrawallistData.damageCansData &&
      adminWithdrawallistData.damageCansData.length > 0 ? (
        <AccceptRejectCard withdrawDet={withdrawDet} />
      ) : null} */}
    </Box>
  );
}
