import React from "react";
import BuyBackOrderDetailsTable from "../../Components/Tables/BuyBackOrderDetailsTabel";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import CreditNoteDetailsMain from "../BuyBackProducts/CreditNotesMain";

export default function BuyBackOrderMain() {
  const [selectedToggelOption, setSelectedToggleOption] =
    React.useState("Orders");
  const handleChangeToggle = (event, newAlignment) => {
    if (newAlignment !== null) {
      setSelectedToggleOption(newAlignment);
    }
  };
  return (
    <>
      <Box>
        <ToggleButtonGroup
          color="primary"
          value={selectedToggelOption}
          onChange={handleChangeToggle}
          aria-label="Platform"
          exclusive
          size="small"
        >
          <ToggleButton value="Orders">Buy Back Orders</ToggleButton>
          <ToggleButton value="Credit">Credit Note Details</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {selectedToggelOption === "Orders" && (
        <BuyBackOrderDetailsTable page_size={3} />
      )}
      {selectedToggelOption === "Credit" && (
        <CreditNoteDetailsMain  />
      )}
    </>
  );
}
