import { Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export default function FileUploadButton({
  buttonName,
  styles,
  handelFileUpload,
}) {
  return (
    <Button
      component="label"
      role={undefined}
      size="small"
      variant="contained"
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
      sx={styles}
    >
      {buttonName}
      <input
        type="file"
        style={{ display: "none" }}
        onChange={handelFileUpload}
      />
    </Button>
  );
}
